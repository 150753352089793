import React from 'react'
import { Button } from '../shadcn/ui/button'
import { useNavigate } from 'react-router-dom'
import examKeys from '../id_helpers/exam_ids.json'
import { LibraryBig, LineChart } from 'lucide-react'
import { Separator } from '../shadcn/ui/separator'

function UserSubscriptions({ activeSubscriptions, expiredSubscriptions }) {
  const navigate = useNavigate()

  // Create a set of active subscription exam IDs
  const activeExamIds = new Set(activeSubscriptions.map(sub => sub.exam_id));

  // Filter expired subscriptions to exclude those with an active subscription of the same exam ID
  const filteredExpiredSubscriptions = expiredSubscriptions.filter(sub => !activeExamIds.has(sub.exam_id));
  const handleNavigation = (examId) => {
    if (examId === 1){
        navigate('/MSRA/home')
    }
  }

  return (
    <div>
      {activeSubscriptions.length === 0 && expiredSubscriptions.length === 0 && (
        <>
          <section>
            <h2 className="text-xl font-semibold mb-4">Active Subscriptions</h2>
            <div className="space-y-4">
              <p>We are currently only running beta testing. If you are interested in testing and have not yet been in contact with me, please email me at: <a href="mailto:exam.vitals@gmail.com" className='hover:underline'>exam.vitals@gmail.com</a>. 
              <br />Once you have created your account, please allow me 24 hours to grant you priveleges. Thank you for helping me build my product!</p>
              {/* <p>You have not purchased a subscription yet. Currently we have the following question banks: MSRA.</p>
              <div className="flex justify-center mt-4"> 
                <Button onClick={() => navigate('/pricing/MSRA')} variant="outline" size="sm">
                  View subscription options
                </Button>
              </div> */}
            </div>
          </section>
        </>
      )}
      {activeSubscriptions.length > 0 && (
        <section>
          <h2 className="text-xl font-semibold mb-4">Active Subscriptions</h2>
          {activeSubscriptions.map(sub => (
            <div key={sub.id} className="space-y-4">
              <div className="bg-background rounded-lg p-4 shadow-sm">
                <div className="flex items-center justify-between mb-2">
                  <div className="font-medium">
                    {examKeys.find(exam => exam.id === sub.exam_id)?.full_name}
                  </div>
                  <div className="text-muted-foreground text-sm">
                    Expires on {sub.end_date}
                  </div>
                </div>
                <div className="text-muted-foreground text-sm">
                  Started on {sub.start_date}
                </div>
                <div className="mt-4 flex flex-wrap justify-between gap-2 sm:flex-row sm:gap-3">
                {/* WILL NEED TO CHANGE THE NUMBER PASSED THROUGH TO HANDLENAVIGATION WHEN MORE EXAMS ARE MADE */}
                  <Button 
                    variant='outline'
                    onClick={() => handleNavigation(1)} 
                    size="sm"
                    >
                    Question Bank
                  </Button>
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/MSRA/textbook/home')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LibraryBig
                        size={14}
                        />
                        <span>Textbook</span>
                    </span>
                  </Button>
                  {/* <Button 
                    className='sm:hidden hover:cursor-pointer'
                    onClick={() => navigate('/MSRA/textbook/home')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LibraryBig
                        size={14}
                        />
                        <span>Textbook</span>
                    </span>
                  </Button> */}
                  <Button 
                    className='hover:cursor-pointer'
                    onClick={() => navigate('/MSRA/dashboard')}
                    variant="link" 
                    size="sm"
                    asChild
                    >
                    <span className='gap-1 items-center'>
                      <LineChart size={14}/>
                      <span>My Performance</span>
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </section>
      )}
      {filteredExpiredSubscriptions.length > 0 && (
        <section>
          <h2 className="text-xl font-semibold mb-4">Expired Subscriptions</h2>
          {filteredExpiredSubscriptions.map(sub => (
            <div key={sub.id} className="space-y-4">
              <div className="bg-background rounded-lg p-4 shadow-sm">
                <div className="flex items-center justify-between mb-2">
                  <div className="font-medium">
                    {examKeys.find(exam => exam.id === sub.exam_id)?.full_name}
                  </div>
                  <div className="text-muted-foreground text-sm">
                    Expired on {sub.end_date}
                  </div>
                </div>
                <div className="text-muted-foreground text-sm">
                  Started on {sub.start_date}
                </div>
                <div className="mt-4">
                  <Button variant="outline" size="sm">
                    Renew Subscription
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </section>
      )}
    </div>
  )
}

export default UserSubscriptions
