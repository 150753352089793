export async function handleSubmitQuestionError(event, questionError, questionOrder, currentQuestionIndex, setQuestionError, accessToken, csrfToken, questionErrorSuccess, setQuestionErrorSuccess) {
    event.preventDefault();
    
    // Define the payload
    const payload = {
        content: questionError,
        question_id: questionOrder[currentQuestionIndex],
    };

    try {
        // Send a POST request to the backend
        const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/questions/question-error-report/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Include token for authentication
                'X-CSRFToken': csrfToken
            },
            body: JSON.stringify(payload),
        });

        // Check if the request was successful
        if (!response.ok) {
            const errorData = await response.json();
            console.error("Error submitting question error:", errorData);
            return;
        }

        // Clear the input on successful submission
        setQuestionError('');
        setQuestionErrorSuccess(true);
        // console.log("Question error submitted successfully");

    } catch (error) {
        console.error("An error occurred:", error);
    }
}
