import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../../csrfTokenHelper';

export const useFetchHistogramData = (token) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!token) return
        const fetchHistogramData = async () => {
            try {
                const csrfToken = getCsrfToken();
                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/overall-stats/histogram/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken
                    }
                });


                if (!response.ok) throw new Error('Failed to fetch histogram data');

                const data = await response.json();
                setData(data);
            } catch (error) {
                console.error('Error fetching histogram data:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchHistogramData();
    }, [token]);

    return { data, loading, error };
};