import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchMSRASessionData = (paperId, token) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token) {
            return; // Exit the useEffect if the token is not yet available
        }
        const fetchSession = async () => {
            try {
                const csrfToken = getCsrfToken();

                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user-session/active/${paperId}/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,  // Add CSRF token to headers
                    }
                });

                if (response.status === 404){
                    return
                }

                if (!response.ok) throw new Error('Failed to fetch session');

                const data = await response.json();
                setData(data);
            } catch (error) {
                console.error('Error fetching session:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSession();
    }, [token]);

    return { data, loading, error };
};