import React from 'react'
import HeaderLarge from '../typography/HeaderLarge'
import TextbookHomePage from './TextbookHomePage'

const TextbookDisplay = ({textbookItem, param, loading, singleLoading}) => {
  
    return (
    <div className='w-full md:w-4/5 mx-auto py-2 px-6'>
      {param === 'home' ? 
        <div className="my-4">
          <TextbookHomePage />
        </div>
      :
      <>
        {textbookItem ? 
          <>
            <div className="my-4">
              <HeaderLarge blackText={true} text={textbookItem.title} underline={true} />
            </div>
            <div id="ckeditor" className="ckeditor" dangerouslySetInnerHTML={{ __html: textbookItem.content}} />
            
          </>      
        :
        <>
          {singleLoading || loading ? 
          null 
          :
          <>
            <div className="my-4 text-center ">
              <HeaderLarge blackText={true} text={`Oops! Looks like that textbook page doesn't exist!`}/>
            </div>
          </>
          }
        </>
        }
      </>
      }
      
    </div>
  )
}

export default TextbookDisplay