import React from 'react'
import { Card, CardHeader, CardTitle, CardContent } from '../../../../components/shadcn/ui/card'
import { Separator } from '../../../../components/shadcn/ui/separator'
import { CheckCheck } from 'lucide-react'

const PerformanceCard = ({ CPSdata, PDdata, selected, categoryCard }) => {

const performanceData = selected === 'Clinical Problem Solving' ? CPSdata.performance : selected === 'Professional Dilemmas' ? PDdata.performance : null

  return (
    <Card className='w-full md:col-start-1 md:col-span-2 lg:col-span-1'>
        <CardHeader>
            <div className="flex items-center justify-between">
                <CardTitle className="text-sm font-medium">Performance</CardTitle>
                <CheckCheck size={18} />
            </div>
            <Separator orientation="horizontal" />
        </CardHeader>
        <CardContent>
            {categoryCard ? (
            <>
                <div className="text-2xl font-bold">
                    {selected.performance.performance_percentage.toFixed(1)}% 
                    <span className="text-sm text-muted-foreground"> correct</span>
                </div>
                <p className="text-xs text-muted-foreground">
                ({selected.performance.total_points_scored} correct / {selected.performance.total_possible_points} total possible points)
                </p>
            </>
            ) : (
            <>
                <div className="text-2xl font-bold">
                    {performanceData.performance_percentage.toFixed(1)}% 
                    <span className="text-sm text-muted-foreground"> correct</span>
                </div>
                <p className="text-xs text-muted-foreground">
                ({performanceData.total_points_scored} correct / {performanceData.total_possible_points} total possible points)
                </p>
            </>
            )}
            
        </CardContent>
    </Card>  
    )
}

export default PerformanceCard