import React, { useState } from 'react';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../shadcn/ui/hover-card';
import { Button } from '../shadcn/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../shadcn/ui/dialog'
import HeaderMedium from '../typography/HeaderLarge';

function HomePageQuestionDisplay({ userAnswer, setUserAnswer, isAnswered, setIsAnswered}) {

  // Hardcoded question and answer data
  const question = {
    id: 'demo-1',
    content: 'Which of the following is the capital of France?',
    options: [
      { id: 'option-1', content: 'Bring the patient in for a clinic blood pressure reading.' },
      { id: 'option-2', content: 'Make no change in management' },
      { id: 'option-3', content: 'Add ramipril' },
      { id: 'option-4', content: 'Add spironolactone' },
      { id: 'option-4', content: 'Add felodipine' }
    ],
    answerPercentage: [
      {
        index: 0,
        percentage: 5, 
      },
      {
        index: 1,
        percentage: 15, 
      },
      {
        index: 2,
        percentage: 60, 
      },
      {
        index: 3,
        percentage: 10, 
      },
      {
        index: 4,
        percentage: 10, 
      },
    ],
    answer: 2 
  };

  function submitAnswer() {
    if(userAnswer === null) return

    setIsAnswered(true);
  }

  const handleAnswerChange = (e) => {
    setUserAnswer(Number(e.target.value));
  }

  return (
    <div className="flex flex-col w-full lg:w-[50vw] md:p-8 p-2 mx-auto">
      <div className="text-center">
        <HeaderMedium
          text={`Example Question`}
          blackText={true}
          underline={true}
          my={2}
        />
      </div>
      <div className="p-4 mb-4 border-b border-gray-200">
        You are a Foundation Year 2 doctor in General Practice reviewing a 60-year-old White British male in clinic. 
         His ambulatory blood pressure readings come back 138/90 mmHg. 
         He is currently prescribed amlodipine at the maximum dose, has no other comorbidities, and does not take any other medication. 
         He reports good concordance with his medication.
        <br />
        <br />
        How would you manage him?
      </div>
      <div className="text-sm italic text-gray-600 mb-4 dark:text-gray-300">
        Please select the most appropriate answer.
      </div>
      {question.options.map((option, index) => {
        const isUserAnswer = index === userAnswer;
        const isCorrectAnswer = index === question.answer;
        const bgColor = isAnswered
          ? (
            isCorrectAnswer ? 'bg-green-200 dark:bg-green-800' :
            isUserAnswer && !isCorrectAnswer ? 'bg-red-200 dark:bg-red-800' :
            ''
          )
          : '';

        return (
          <label
            key={option.id}
            className={`flex items-center dark:text-white mb-4 p-4 border rounded shadow-sm ${bgColor} ${!isAnswered ? 'dark:hover:bg-black/20 hover:bg-gray-100 cursor-pointer' : ''} dark:text-black`}
          >
            <input
              type="radio"
              name={`question-${question.id}`}
              value={index}
              checked={userAnswer === index}
              onChange={handleAnswerChange}
              className="mr-2"
              disabled={isAnswered} // Disabled for demo purposes
            />
            <div className="items-center "style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <div dangerouslySetInnerHTML={{ __html: option.content }} />
              {isAnswered && (
                <HoverCard>
                  <HoverCardTrigger asChild>
                    <div className='text-sm text-muted-foreground' style={{ whiteSpace: 'nowrap' }}>
                    {question.answerPercentage.find((i) => i.index === index).percentage}%
                    </div>
                  </HoverCardTrigger>
                  <HoverCardContent className="w-80">
                    <div className="flex justify-between space-x-4">
                      <div className="space-y-1">
                        <h4 className="text-sm font-semibold">Of all users who attempted this question, {question.answerPercentage.find((i) => i.index === index).percentage}% selected this option as their answer.</h4>
                      </div>
                    </div>
                  </HoverCardContent>
                </HoverCard>
              )}
            </div>
          </label>
        );
      })}
      <div className="flex justify-end">
        <Button 
          className={`w-full md:w-auto ${isAnswered ? 'hidden' : ''}`} 
          onClick={submitAnswer} 
          disabled={isAnswered}
        >
          Submit Answer
        </Button>
      </div>

      {isAnswered && (
        <>
          <div className="mt-4 font-semibold">
            {userAnswer !== question.answer ? (
              <span>Incorrect. <br />Correct Answer: {question.options[question.answer].content}</span>
            ) : (
              <span className="text-green-500">Your answer is correct</span>
            )}
          </div>
          <Dialog>
          <div>
            <span className='text-gray-600 dark:text-gray-300 italic'>
              Textbook page:&nbsp;
            </span>
              <DialogTrigger className='font-semibold hover:underline cursor-pointer'>Hypertension</DialogTrigger>
          </div>
            <DialogContent>
              <DialogHeader>
                <DialogTitle className='mb-1'>
                  Preview content
                </DialogTitle>
                <DialogDescription>
                  This is just a preview. When you purchase our question banks, clicking the textbook page link will redirect you to the textbook page so you can review the topic in more detail.
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
          <br />
          <div className="underline text-gray-600 dark:text-gray-300">Explanation:</div>
          <p>Blood pressure targets for those aged &lt;80 are &lt;135/85mmHg for ABPM. He is already on a calcium channel blocker, and so the next step would be to add an ACE inhibitor or angiotensin receptor blocker or thiazide-like diuretic. Ramipril is an ACE inhibitor.</p>
        </>
      )}
    </div>
  );
}

export default HomePageQuestionDisplay;
