import React, { useState, useEffect, useCallback } from 'react';
import { HoverCard, HoverCardContent, HoverCardTrigger, } from '../../../shadcn/ui/hover-card';


export const MCQDisplay = ({ questions, isAnswered, userAnswer, onUserAnswerChange, fetchedUserAnswer, manualScore, questionStats, reviewState }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckboxChange = useCallback((index) => {
        setSelectedOptions(currentSelected => {
            if (currentSelected.includes(index)) {
                return currentSelected.filter(item => item !== index);
            } else if (currentSelected.length < 3) {
                return [...currentSelected, index];
            }
            return currentSelected;
        });
    }, []);

    useEffect(() => {
        if (selectedOptions.length === 3) {
            const sortedOptions = [...selectedOptions].sort((a, b) => a - b);
            onUserAnswerChange(sortedOptions);
        }
    }, [selectedOptions]);

    return (
        <div className="flex flex-col">
            {reviewState && !isAnswered && (
            <div className="font-semibold italic text-gray-600 text-center underline my-4 dark:text-gray-300">
                You did not answer this question.
            </div>
            )}
            <div 
                className="p-4 mb-4 border-b border-gray-200"  // Added padding and a bottom border for separation
                dangerouslySetInnerHTML={{ __html: questions.content }} 
            />
            <div className="text-sm italic text-gray-600 mb-4">  {/* Instruction text */}
                Please select the <strong>three</strong> correct answers.
            </div>
            {questions.options && !isAnswered && (
                questions.options.map((option, index) => (
                    <label key={option.id} className={`flex items-center dark:text-white mb-4 p-4 border rounded shadow-sm ${!isAnswered && !reviewState && 'dark:hover:bg-gray-900 hover:bg-gray-100 cursor-pointer'} dark:text-black`}>
                        <input 
                            type="checkbox"
                            name={`question-${questions.id}`}
                            value={index}
                            checked={selectedOptions.includes(index)}
                            onChange={() => handleCheckboxChange(index)}
                            className="mr-2"
                            disabled={isAnswered || reviewState}
                        />
                        <div dangerouslySetInnerHTML={{ __html: option.content }} />
                    </label>
                ))
            )}
            {questions.options && isAnswered && (
                questions.options.map((option, index) => {
                    const statsPercentage = questionStats.option[index].percentage.toFixed(2)
                    const userChoice = fetchedUserAnswer ? fetchedUserAnswer.user_answer.includes(index) : 
                        userAnswer.includes(index)
                    const correctAnswer = questions.mcq_answer.includes(index)

                    const optionColor = userChoice && !correctAnswer ? 'bg-red-300 dark:bg-red-900 hover:bg-red-200 dark:hover:bg-red-800' :
                        correctAnswer ? 'bg-green-300 dark:bg-green-900 hover:bg-green-200 dark:hover:bg-green-800' : '' 
                    return (
                    <label key={option.id} className={`flex items-center dark:text-white mb-4 p-4 border rounded shadow-sm ${optionColor} dark:text-black cursor-pointer`}>
                        <input 
                            type="checkbox"
                            name={`question-${questions.id}`}
                            value={index}
                            checked={userChoice}
                            className="mr-2"
                            disabled={true}
                        />
                        <div className="items-center "style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>  {/* Adjusted for vertical alignment */}
                            <div dangerouslySetInnerHTML={{ __html: option.content }} />

                            <HoverCard>
                                <HoverCardTrigger asChild>
                                    <div className='text-sm text-muted-foreground' style={{ whiteSpace: 'nowrap' }}>{statsPercentage}%</div>
                                </HoverCardTrigger>
                                <HoverCardContent className="w-80">
                                    <div className="flex justify-between space-x-4">                        
                                        <div className="space-y-1">
                                            <h4 className="text-sm font-semibold">Of all users who attempted this question, {statsPercentage}% of users included this option as one of their three answers.</h4>
                                        </div>
                                    </div>
                                </HoverCardContent>
                            </HoverCard>

                        </div>
                    </label>
                )})
            )}
            {isAnswered && (
                <>
                    <div className="mt-4">
                        <strong>Score for this question: </strong>{fetchedUserAnswer ? `${fetchedUserAnswer.points_scored} / ${fetchedUserAnswer.total_points}` : `${manualScore[questions.id]} / 12`}
                    </div>
                    {questions.explanation && (
                        <>
                            <div className="underline italic text-gray-600 dark:text-gray-300">
                                Explanation:
                            </div>
                            <div className="mb-4" dangerouslySetInnerHTML={{ __html: questions.explanation }} />
                        </>
                    )}
                        {/* {questions.textbook_page ? (
                            <>Handle textbook page relation</>
                        ) : null
                        } */}
                </>
            )}
        </div>
    );
};
