import HeaderMedium from '../../components/typography/HeaderLarge'
import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom';

function TermsAndConditions() {
  const listStyle = "mt-2 mb-6 ml-2 space-y-1"

  useEffect(() => {
    const scrollToHash = () => {
      const { hash } = window.location;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Scroll on initial render if there's a hash
    scrollToHash();

    // Add event listener for hash changes
    window.addEventListener('hashchange', scrollToHash);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  }, []);

  return (
    <div className='lg:py-8 md:py-4 py-2 px-4 lg:w-3/4 md:w-4/5 w-full mx-auto'>
        <section>
          <h3 className={`text-center text-black dark:text-white my-4 underline scroll-m-20 text-3xl font-bold tracking-tight`}>
            Terms and Conditions
          </h3>
          <p>
          Welcome to Exam Vitals. These Terms and Conditions govern your use of our platform, and by accessing our services, you agree to these terms. Please read them carefully, and reach out to us at <a href='mailto:exam.vitals@gmail.com' className='hover:underline'>exam.vitals@gmail.com</a> with any questions.
          </p>
          <br />
          
        </section>
        
        <section id='purpose-and-acceptance-of-terms'>
          <HeaderMedium
            text='Purpose and Acceptance of Terms'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
          Exam Vitals is an educational resource for doctors preparing for post-graduate medical exams. Our platform offers carefully curated, evidence-based materials to enhance your knowledge and readiness for exams. Your use of Exam Vitals signifies your acceptance of these Terms and Conditions. If you disagree with any part, please refrain from using our services.
            <br /><br />
          </p>
        </section>
        
        <section id='subscription-and-payment'>
          <HeaderMedium
            text='Subscription and Payment'
            blackText={true}
            underline={true}
            my={2}
          />
          <ul className={listStyle}>
          <li>
              •	<strong>Payment</strong>: Subscription fees are listed in GBP (£). For non-GBP accounts, your card provider may apply exchange rates.
            </li>
            <li>
              •	<strong>Cancellations and Refunds</strong>: Refund requests are considered on a case-by-case basis at our discretion. Once a subscription begins, refunds may not be available.
            </li>
            <li>
              •	<strong>Payment Details</strong>: By subscribing, you confirm that your payment details are accurate, the card used is your own, and there are sufficient funds to cover the subscription.
            </li>
          </ul>
        </section>


        <section id='personal-use-and-account-security'>
          <HeaderMedium
            text='Personal Use and Account Security'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
            <ul className={listStyle}>
              <li>
              •	<strong>Account Usage</strong>: Exam Vitals subscriptions are personal and non-transferable. Each user must keep their account details secure and not share login information.
              </li>
              <li>
              •	<strong>Prohibited Actions</strong>: Unauthorized access attempts, tampering, reproducing content, or sharing login credentials are strictly prohibited and may lead to immediate termination of access.
              </li>
            </ul>
          </p>
        </section>

        <section id='beta-testing'>
          <HeaderMedium
            text='Beta Testing'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>Exam Vitals may grant selected users access to free beta testing resources. During this phase, all Terms and Conditions apply, including prohibitions on reproducing or distributing content. Beta users should expect regular maintenance and occasional service disruptions as we enhance our platform. Exam Vitals reserves the right to terminate beta access at any time, without prior notice or compensation.</p>
          <br />
        </section>

        <section id='service-availability'>
          <HeaderMedium
            text='Service Availability'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
          We strive for consistent platform availability; however, occasional maintenance or technical adjustments may cause temporary disruptions. In cases of significant downtime, we will make reasonable adjustments to your subscription as appropriate.
            <br /><br />
          </p>
        </section>

        <section id='external-links-disclaimer'>
          <HeaderMedium
            text='External Links Disclaimer'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
          Exam Vitals may include links to third-party sites solely for your convenience. These links do not imply endorsement, and Exam Vitals disclaims responsibility for any external content or consequences of accessing third-party sites.
          </p>
          <br />
        </section>

        <section id='copyright-and-intellectual-property'>
          <HeaderMedium
            text='Copyright and Intellectual Property'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
          All materials on Exam Vitals, including content, design, and databases, are protected by copyright and are intended solely for personal, non-commercial use. Reproducing, sharing, or displaying materials outside of individual study is strictly prohibited without prior written consent. All intellectual property rights remain with Exam Vitals.            <br />
          </p>
          <br />
        </section>

        <section id='limitation-of-liability'>
          <HeaderMedium
            text='Limitation of Liability'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
            <ul className={listStyle}>
              <li>
              •	<strong>Content Accuracy</strong>: Exam Vitals endeavours to keep content up-to-date, but medical knowledge is constantly evolving. There may be occasional delays in updating materials, and Exam Vitals assumes no liability for any inaccuracies.
              </li>
              <li>
              •	<strong>Educational Use Only</strong>: The resources on this platform are intended for educational purposes to help users pass exams and are not a substitute for clinical practice.
              </li>
              <li>
              •	<strong>Liability Limitation</strong>: Exam Vitals’ liability for any damages or losses is limited to the amount of the subscription fee paid by the user.
              </li>
            </ul>
          </p>
        </section>

        <section id='privacy-policy'>
          <HeaderMedium
            text='Privacy Policy'
            blackText={true}
            underline={true}
            my={2}
            />
            <p className='ml-2'>
            Exam Vitals is committed to protecting your personal data in accordance with our <Link to={'/privacy-policy'}><strong className="hover:underline">Privacy Policy</strong></Link>. By using the platform, you consent to the processing of your personal data as outlined in this <Link to={'/privacy-policy'}><strong className="hover:underline">policy</strong></Link>.  
            </p>
            <br />
        </section>

        <section id='governing-law'>
          <HeaderMedium
            text='Governing Law'
            blackText={true}
            underline={true}
            my={2}
            />
            <p className='ml-2'>
            These Terms and Conditions are governed by the laws of England and Wales. Exam Vitals reserves the right to take legal action in relevant jurisdictions, if necessary, to enforce these terms.
            </p>
            <br />
        </section>

        <section id='corrections'>
          <HeaderMedium
            text='Corrections'
            blackText={true}
            underline={true}
            my={2}
            />
            <p className='ml-2'>
            There may be information on the Services that contain typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice. 
            </p>
            <br />
        </section>

        <section id='contact-us'>
          <HeaderMedium
            text='Contact Us'
            blackText={true}
            underline={true}
            my={2}
            />
            <p className='ml-2'>
            In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:
            </p>
            <br />
            <ul className='ml-2'>
              <li>Exam Vitals Limited</li>
              <li>Registered Office (UK) Ltd</li>
              <li>85 Great Portland Street</li>
              <li>First Floor</li>
              <li>London</li>
              <li>W1W 7LT</li>
            </ul>
            <br />
            <p className="ml-2">
              <a href='mailto:exam.vitals@gmail.com' className='hover:underline'>exam.vitals@gmail.com</a>
            </p>
            <br />
            <br />
        </section>

        <section id='contact-us'>
          <p className="ml-2">
          Thank you for choosing Exam Vitals. We look forward to supporting your exam preparation journey with high-quality, trusted resources. For any further inquiries, please reach out to us at <a href='mailto:exam.vitals@gmail.com' className='hover:underline'>exam.vitals@gmail.com</a>.
          </p>
          <br />
        </section>

        <section>
          <HeaderMedium
            text='Last updated'
            blackText={true}
            underline={true}
            my={2}
            />
            <p className='ml-2'>27 October 2024</p>
        </section>
    </div>
  )
}

export default TermsAndConditions