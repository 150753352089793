import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchActiveSubPerformance = (token) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cpsPaper, setCpsPaper] = useState(null);
    const [pdPaper, setPdPaper] = useState(null);
    const [cpsPerformance, setCpsPerformance] = useState(null)
    const [cpsCoverage, setCpsCoverage] = useState(null)
    const [pdPerformance, setPdPerformance] = useState(null)
    const [pdCoverage, setPdCoverage] = useState(null)


    useEffect(() => {
        if (!token) {
            return; 
        }
        const fetchPerformance = async () => {

            try {
                const csrfToken = getCsrfToken();

                let response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/MSRA/user/analytics/active_sub_summary/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-CSRFToken": csrfToken,  // Add CSRF token to headers
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch user performance');

                const data = await response.json();
                setData(data);

                // Extract cpsPaper and pdPaper data
                const cpsPaperData = data.find(item => item.papers.some(paper => paper.name === 'Clinical Problem Solving'));
                const pdPaperData = data.find(item => item.papers.some(paper => paper.name === 'Professional Dilemmas'));


                setCpsPaper(cpsPaperData ? cpsPaperData.papers.find(paper => paper.name === 'Clinical Problem Solving') : null);
                setCpsPerformance(cpsPaperData ? cpsPaperData.papers.find(paper => paper.name === 'Clinical Problem Solving').performance : null)
                setCpsCoverage(cpsPaperData ? cpsPaperData.papers.find(paper => paper.name === 'Clinical Problem Solving').coverage : null)
                setPdPaper(pdPaperData ? pdPaperData.papers.find(paper => paper.name === 'Professional Dilemmas') : null);
                setPdPerformance(pdPaperData ? pdPaperData.papers.find(paper => paper.name === 'Professional Dilemmas').performance : null)
                setPdCoverage(pdPaperData ? pdPaperData.papers.find(paper => paper.name === 'Professional Dilemmas').coverage : null)

            } catch (error) {
                console.error('Error fetching user performance:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPerformance();
    }, [token]);

    return { data, loading, error, cpsPaper, pdPaper, cpsPerformance, cpsCoverage, pdPerformance, pdCoverage };
};
