import React, { useEffect, useState, useCallback } from 'react'
import { 
  fetchTextbookPages,
  fetchUserQuestionStates,
  fetchSessionQuestions,
  enrichQuestionsWithDetails,
  fetchQuestionAttempts,
  fetchQuestionAttemptStats,
  fetchSingleTextbookPage,
} from '../../../../../components/hooks/MSRA/helper_functions/cpsFetches'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchSpecificMSRASessionData } from '../../../../../components/hooks/MSRA/fetches/FetchSpecificMSRASessionData'
import ReviewSessionFormattedQuestionList from '../../../../../components/session_navigation/MSRA/sessionNavigatorForReview'
import { EMQDisplay } from '../../../../../components/hooks/MSRA/question_displays/EMQDisplay' 
import { SBADisplay } from '../../../../../components/hooks/MSRA/question_displays/SBADisplay'
import { Card } from '../../../../../components/shadcn/ui/card'
import { Separator } from '../../../../../components/shadcn/ui/separator'
import { Button } from '../../../../../components/shadcn/ui/button'
import { CircleArrowRight, CircleArrowLeft } from 'lucide-react'
import { useAuth0 } from '@auth0/auth0-react'
import SpinLoader from '../../../../../components/loader/SpinLoader'
import GoBackButton from '../../../../../components/other_ui/GoBackButton'
import FourOhFourPage from '../../../../../pages/errors/FourOhFourPage'

function CPSSingleSessionReview() {
  const { id } = useParams()
  const [questionOrder, setQuestionOrder] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questionStates, setQuestionStates] = useState([]);
  const [originalQuestionOrder, setOriginalQuestionOrder] = useState([]);
  const [expandedQuestionOrder, setExpandedQuestionOrder] = useState([]);
  const [questionAttemptsStats, setQuestionAttemptsStats] = useState([]);
  const [textbookContent, setTextbookContent] = useState([])
  const [questions, setQuestions] = useState([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [questionsError, setQuestionsError] = useState(null);
  const [questionAttempts, setQuestionAttempts] = useState({});
  const [overallScore, setOverallScore] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [scorePercentage, setScorePercentage] = useState(0.0);
  const [userAnswer, setUserAnswer] = useState({}); // Now an object to track answers by question ID
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate()
  const [wrongSession, setWrongSession] = useState(false)


  const [isCollapsed, setIsCollapsed] = useState(false);

  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [token, setToken] = useState(null);
  const [tokenLoading, setTokenLoading] = useState(true);
  
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
      } catch (error) {
        console.error('Error fetching token:', error);
      } finally {
        setTokenLoading(false);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  const { data, loading, error } = useFetchSpecificMSRASessionData(id, token)


  useEffect(() => {
    if (data && data.paper){
      if (data.paper !== 1 || data.is_active){
        setWrongSession(true)
      }
    }
  }, [data])

    useEffect(() => {
      if (wrongSession || !data || !data.id || !token) return;
  
      async function initializeData() {
          setQuestionsLoading(true);
          
          try {
              if (data.question_order) {
                  const parsedQuestionOrder = JSON.parse(data.question_order);
                  setOriginalQuestionOrder(parsedQuestionOrder);
                  setQuestionOrder(parsedQuestionOrder);
                  loadTextbookPage(JSON.parse(data.question_order)[0])
              }
              
              const questions = await fetchSessionQuestions(data.id, token);
              const questionsWithDetails = await enrichQuestionsWithDetails(questions, token); // Explicitly await and pass token here
  
              const [attemptsResponse, questionStatesData, questionAttemptsStats] = await Promise.all([
                  fetchQuestionAttempts(data.id, token),
                  fetchUserQuestionStates(data.id, token),
                  fetchQuestionAttemptStats(data.id, token),
              ]);
    
              setQuestions(questionsWithDetails);
              processAttemptsData(attemptsResponse);
              setQuestionStates(questionStatesData);
              setExpandedQuestionOrder(JSON.parse(data.expanded_question_order));
              setQuestionAttemptsStats(questionAttemptsStats)
          } catch (error) {
              console.error('Failed to fetch session details:', error);
              setQuestionsError(error);
          } finally {
              setQuestionsLoading(false);
          }
      }
    
      initializeData();
  }, [data, token]);  
    

  const loadTextbookPage = async (questionId) => {
    if (!textbookContent[questionId]) {
        try {
            const textbookPage = await fetchSingleTextbookPage(questionId, token);
            setTextbookContent((prev) => ({
                ...prev,
                [questionId]: textbookPage,
            }));
        } catch (error) {
            console.error(`Failed to fetch textbook page for question ${questionId}:`, error);
        }
    }
};

useEffect(() => {
    const currentQuestionId = questionOrder[currentQuestionIndex];
    if (currentQuestionId && currentQuestionState?.answered) {
        loadTextbookPage(currentQuestionId); // Fetch textbook page if not already fetched
    }
}, [currentQuestionIndex, questionOrder]);

    function processAttemptsData(attempts) {
      const attemptsByQuestionId = {};
      let totalScore = 0;
      let totalPossiblePoints = 0;
    
      attempts.forEach(attempt => {
        attemptsByQuestionId[attempt.question] = attempt;
        totalScore += attempt.points_scored;
        totalPossiblePoints += attempt.total_points;
      });
      setQuestionAttempts(attemptsByQuestionId);
      setOverallScore(totalScore);
      setTotalPoints(totalPossiblePoints);
      setScorePercentage(((totalScore / totalPossiblePoints) * 100).toFixed(1));
    }

  const goToPreviousQuestion = useCallback(() => {
      if (currentQuestionIndex > 0) {
          let newIndex = currentQuestionIndex - 1;
          setCurrentQuestionIndex(newIndex);
      }
  }, [currentQuestionIndex]);

  const goToNextQuestion = useCallback(() => {
      if (currentQuestionIndex < questionOrder.length - 1) {
          let newIndex = currentQuestionIndex + 1;
          setCurrentQuestionIndex(newIndex);
      }
  }, [currentQuestionIndex, questionOrder.length]);

  const navigateToQuestion = (questionNumber) => {
      setCurrentQuestionIndex(questionNumber)
  };
  
  const getCurrentQuestionDisplay = () => {
      const currentQuestionId = questionOrder[currentQuestionIndex];
      const currentQuestionState = questionStates.find(state => state.question_details.id === currentQuestionId);
  
      
      if (currentQuestionState && currentQuestionState.question_details.question_type === 2) {
          // Find the index of the first occurrence of the current question ID in the expandedQuestionOrder
          const startRange = expandedQuestionOrder.indexOf(currentQuestionId) + 1
      
          // End range is startRange + scenario_count - 1
          const endRange = startRange + currentQuestionState.question_details.scenario_count - 1;
          return `Questions ${startRange}-${endRange} of ${expandedQuestionOrder.length}`;
      }
      return `Question ${expandedQuestionOrder.indexOf(currentQuestionId) + 1} of ${expandedQuestionOrder.length}`;
  };

  // for displaying question content
  const currentQuestionId = questionOrder[currentQuestionIndex];
  const currentQuestion = questions.find(q => q.id === currentQuestionId);
  const currentQuestionState = questionStates.find(qs => qs.question_details.id === currentQuestionId);
  const OptionsComponent = currentQuestion && currentQuestion.question_type === 2 ? EMQDisplay : SBADisplay;

  // Navigate questions with arrow keys
  // Define the keydown handler
  const handleKeyDown = useCallback((event) => {
      if (event.key === 'ArrowRight') {
          goToNextQuestion();
      } else if (event.key === 'ArrowLeft') {
          goToPreviousQuestion();
      }
  }, [goToNextQuestion, goToPreviousQuestion]);

  // Add event listener on component mount and cleanup on unmount
  useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
          window.removeEventListener('keydown', handleKeyDown);
      };
  }, [handleKeyDown]);
  
  if (wrongSession || error) return <FourOhFourPage />;
  if (loading || !currentQuestion) return (
      <SpinLoader />
    );


  return (
    <div>
    <GoBackButton 
      navigateTo={'/MSRA/all-session-summary/CPS'}
      altText={'All CPS Sessions'}
    />
    <h5 className="lg:text-3xl text-2xl lg:mt-6 mt-4 font-bold tracking-tight text-center text-gray-900 dark:text-white">Review Session</h5>
    <h5 className="lg:text-lg text-md text-center">You are reviewing a previous session you completed. Answers can't be changed.</h5>
    <div className="flex flex-col justify-center lg:flex-row mx-auto p-1 md:p-4 lg:p-10 gap-8">
        <Card className="flex flex-col w-full lg:w-[50vw] md:p-8 p-2">
            <div className="flex items-center justify-between mb-4">
                <Button 
                    variant={'outline'}
                    className={`hidden md:block ${currentQuestionIndex === 0 ? 'invisible' : ''}`} 
                    onClick={goToPreviousQuestion}
                    >
                    Previous
                </Button>
                <CircleArrowLeft
                    className={`block md:hidden ${currentQuestionIndex === 0 ? 'invisible' : ''}`}
                    onClick={goToPreviousQuestion}
                />
                <h5 className='text-xl font-bold'>
                    {getCurrentQuestionDisplay()}
                </h5>
                <CircleArrowRight
                    className={`block md:hidden ${(currentQuestionIndex === questionOrder.length - 1) ? 'invisible' : ''}`}
                    onClick={goToNextQuestion}
                />
                <Button
                    variant={'outline'}
                    className={`hidden md:block ${(currentQuestionIndex === questionOrder.length - 1) ? 'invisible' : ''}`} 
                    onClick={goToNextQuestion}
                    >
                    Next
                </Button>
            </div>
            <Separator orientation='horizontal' />
            <div className="mb-4">
                <OptionsComponent
                    questions={currentQuestion}
                    isAnswered={currentQuestionState?.answered}
                    data={data}
                    allQuestionAttempts={questionAttempts}
                    localUserAnswer={userAnswer}
                    questionAttemptsStats={questionAttemptsStats}
                    reviewState={true}
                />
            </div>
        </Card>
        <div>
            <Card className="w-full lg:min-w-[17vw] p-4">
                <ReviewSessionFormattedQuestionList
                    expandedQuestionOrder={expandedQuestionOrder}
                    questionStates={questionStates}
                    questionAttempts={questionAttempts}
                    navigateToQuestion={navigateToQuestion}
                    localUserAnswer={userAnswer}
                    currentQuestionIndex={currentQuestionIndex}
                    allQuestions={questions}
                    totalPoints={totalPoints}
                    overallScore={overallScore}
                    reviewState={true}
                /> 
            </Card>
        </div>
    </div>
    {currentQuestionState?.answered && textbookContent[currentQuestionId] && (
        <Card className='w-full lg:w-[75vw] mx-auto p-1 md:p-8 mb-5'>
            <h2 className='scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0' dangerouslySetInnerHTML={{ __html: textbookContent[currentQuestionId].title}}></h2>
            <div id="ckeditor" className="ckeditor-content" dangerouslySetInnerHTML={{ __html: textbookContent[currentQuestionId].content }}></div>
        </Card>
    )}
</div>
  )
}

export default CPSSingleSessionReview