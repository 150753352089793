
import HeaderMedium from '../../components/typography/HeaderMedium'
import React from 'react'
import { useEffect } from 'react';

function Privacy() {
  const listStyle = "mt-2 mb-6 ml-2 space-y-1"

  useEffect(() => {
    const scrollToHash = () => {
      const { hash } = window.location;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Scroll on initial render if there's a hash
    scrollToHash();

    // Add event listener for hash changes
    window.addEventListener('hashchange', scrollToHash);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  }, []);

  return (
    <div className='lg:py-8 md:py-4 py-2 px-4 lg:w-3/4 md:w-4/5 w-full mx-auto'>
        <section>
          <h3 className={`text-center text-black dark:text-white my-4 underline scroll-m-20 text-3xl font-bold tracking-tight`}>
            Exam Vitals Limited Customer Privacy Notice
          </h3>
          <p>
            This privacy notice tells you what to expect us to do with your personal information.
            <ul className={listStyle}>
              <li>
              •	<a href="#contact-details" className="hover:underline">
                Contact details
                </a>
              </li>
              <li>
              •	<a href="#info-collect" className="hover:underline">
                What information we collect, use, and why
              </a>
              </li>
              <li>
              •	<a href="#data-protection" className="hover:underline">
                Lawful bases and data protection rights
                </a>
              </li>
              <li>
              •	<a href="#personal-information" className="hover:underline">
                Where we get personal information from
                </a>
              </li>
              <li>
              •	<a href="#data-retention" className="hover:underline">
                 How long we keep information
              </a>
              </li>
              <li>
              •	<a href="#complaints" className="hover:underline">
                How to complain
                </a>
              </li>
            </ul>
          </p>
        </section>
        
        <section id='contact-details'>
          <HeaderMedium
            text='Contact details'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
            {/* Email: <a href='mailto:contact@exam-vitals.com' className='hover:underline'>contact@exam-vitals.com</a> */}
            Email: <a href='mailto:exam.vitals@gmail.com' className='hover:underline'>exam.vitals@gmail.com</a>
            <br /><br />
          </p>
        </section>
        
        <section id='info-collect'>
          <HeaderMedium
            text='What information we collect, use, and why'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
          We collect or use the following information for <strong>service updates or marketing purposes</strong>:
          <ul className={listStyle}>
          <li>
              •	Names and contact details
            </li>
            <li>
              •	Marketing preferences
            </li>
          </ul>

          We collect or use the following personal information for <strong>dealing with queries, complaints or claims</strong>:
          <ul className={listStyle}>
          <li>
              •	Names and contact details
            </li>
            <li>
            •	Account information
            </li>
            <li>
            •	Purchase or service history
            </li>
            <li>
            •	Correspondence
            </li>
          </ul>

          </p>
        </section>

        <section id='data-protection'>
          <HeaderMedium
            text='Lawful bases and data protection rights'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
            Under UK data protection law, we must have a “lawful basis” for collecting and using your personal information. There is a list of possible lawful bases in the UK GDPR. You can find out more about lawful bases on the ICO’s website.
          <br /><br />
            Which lawful basis we rely on may affect your data protection rights which are in brief set out below. You can find out more about your data protection rights and the exemptions which may apply on the ICO’s website:
            <ul className={listStyle}>
              <li>
              •	<strong>Your right of access</strong> - You have the right to ask us for copies of your personal information. You can request other information such as details about where we get personal information from and who we share personal information with. There are some exemptions which means you may not receive all the information you ask for. <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#roa" className="hover:underline">You can read more about this right here</a>.
              </li>
              <li>
              •	<strong>Your right to rectification</strong> - You have the right to ask us to correct or delete personal information you think is inaccurate or incomplete. <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtr" className="hover:underline">You can read more about this right here</a>.
              </li>
              <li>
              •	<strong>Your right to erasure</strong> - You have the right to ask us to delete your personal information. <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rte" className="hover:underline">You can read more about this right here</a>.
              </li>
              <li>
              •	<strong>Your right to restriction of processing</strong> - You have the right to ask us to limit how we can use your personal information. <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtrop" className="hover:underline">You can read more about this right here</a>.
              </li>
              <li>
              •	<strong>Your right to object to processing</strong> - You have the right to object to the processing of your personal data. <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rto" className="hover:underline">You can read more about this right here</a>.
              </li>
              <li>
              •	<strong>Your right to data portability</strong> - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you. <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtdp" className="hover:underline">You can read more about this right here</a>.
              </li>
              <li>
              •	<strong>Your right to withdraw consent</strong> – When we use consent as our lawful basis you have the right to withdraw your consent at any time. <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtwc" className="hover:underline">You can read more about this right here</a>.
              </li>
            </ul>
            If you make a request, we must respond to you without undue delay and in any event within one month.
            <br /><br />
            To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.
            <br /><br />
            <strong className='text-lg'>Our lawful bases for the collection and use of your data</strong>
            <p>
              Our lawful bases for collecting or using personal information for <strong>service updates or marketing purposes</strong> are:
              <ul className={listStyle}>
                <li>
                  •	Consent - we have permission from you after we gave you all the relevant information. All of your data protection rights may apply, except the right to object. To be clear, you do have the right to withdraw your consent at any time.
                </li>
                <li>
                  •	Contract – we have to collect or use the information so we can enter into or carry out a contract with you. All of your data protection rights may apply except the right to object.
                </li>
              </ul>
            </p>
            <p>
              Our lawful bases for collecting or using personal information for <strong>dealing with queries, complaints or claims are</strong>:
              <ul className={listStyle}>
                <li>
                  •	Consent - we have permission from you after we gave you all the relevant information. All of your data protection rights may apply, except the right to object. To be clear, you do have the right to withdraw your consent at any time.
                </li>
                <li>
                  •	Contract – we have to collect or use the information so we can enter into or carry out a contract with you. All of your data protection rights may apply except the right to object.
                </li>
              </ul>
            </p>
          </p>
        </section>

        <section id='personal-information'>
          <HeaderMedium
            text='Where we get personal information from'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>Directly from you through the sign up form you complete.</p>
          <br />
        </section>

        <section id='data-retention'>
          <HeaderMedium
            text='How long we keep information'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
            <strong className="text-lg">Waiting list sign up</strong>
            <p>To keep you updated on the release of our product, and information regarding beta testing (if you consent to this during sign up to our waiting list), we collect the following information that you provide:
              <ul className={listStyle}>
                <li>
                  •	Your full name
                </li>
                <li>
                •	Your email address
                </li>
                <li>
                •	Information about where you have heard about us
                </li>
              </ul>
              <p>We store this information electronically.</p>
              <br />
              
              <p>All users who have signed up to the waiting list will receive a single email correspondence once the product is ready. </p>
              <br />
              <p>If you have opted to be considered for beta testing, you will receive email correspondence regarding eligibility and further instructions. </p>
              <br />
              <p>We will delete all your collected information once we have released our product, or by 26 September 26, 2025, whichever is sooner.</p>
              <br />
              <p>If you sign up but wish to remove yourself from the mailing list, please contact us and we will arrange this.</p>
            </p>
            <br />
          </p>
        </section>

        <section id='complaints'>
          <HeaderMedium
            text='How to complain'
            blackText={true}
            underline={true}
            my={2}
          />
          <p className='ml-2'>
            <p>
              If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.
            </p>
            <br />
            <p>
              If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.
            </p>
            <br />
            <p>
              The ICO’s address:    
              <div className="mt-2 ml-4">
                Information Commissioner’s Office
                <br />
                Wycliffe House
                <br />
                Water Lane
                <br />
                Wilmslow
                <br />
                Cheshire
                <br />
                SK9 5AF
                <br />
                <br />
                Helpline number: 0303 123 1113
                <br />
                <br />
                Website: <a href="https://www.ico.org.uk/make-a-complaint" className='hover:underline'>https://www.ico.org.uk/make-a-complaint</a>
              </div>
            </p>
            <br />
          </p>
        </section>

        <section>
          <HeaderMedium
            text='Last updated'
            blackText={true}
            underline={true}
            my={2}
            />
            <p className='ml-2'>26 September 2024</p>
        </section>
    </div>
  )
}

export default Privacy