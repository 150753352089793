import { useState, useEffect } from 'react';
import { getCsrfToken } from '../../csrfTokenHelper';

export const useFetchMSRAPapers = (token) => {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPapers = async () => {
      if (!token) return;

      try {
        const csrfToken = getCsrfToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}custom_api/questions/MSRA/papers/`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRFToken": csrfToken
          }
        });

        if (!response.ok) throw new Error('Failed to fetch Papers');

        const data = await response.json();
        setPapers(data.papers);
      } catch (error) {
        console.error('Error fetching papers:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPapers();
  }, [token]);

  return { papers, loading, error };
};
