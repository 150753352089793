import React from 'react';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../../components/shadcn/ui/select';
import { Separator } from '../../../components/shadcn/ui/separator';
import PercentileCard from './cards/PercentileCard';
import PerformanceCard from './cards/PerformanceCard';
import CoverageCard from './cards/CoverageCard';
import BarChart from '../../visualisation/BarChart';
import PieChart from '../../visualisation/PieChart';

function PaperPerformance({ selectedPaper, CPSdata, PDdata, percentileData, setSelectedPaper, papers, histogramData }) {
  const isCPSDataAvailable = CPSdata && CPSdata.performance;
  const isPDDataAvailable = PDdata && PDdata.performance;

  const performancePercentage = selectedPaper === 'Clinical Problem Solving' && isCPSDataAvailable
    ? CPSdata.performance.performance_percentage
    : isPDDataAvailable
      ? PDdata.performance.performance_percentage
      : 0;

  const selectedPaperData = selectedPaper === 'Clinical Problem Solving' && isCPSDataAvailable
    ? CPSdata
    : isPDDataAvailable
      ? PDdata
      : {};

  return (
    <div>
      <div className="container mx-auto p-4">
        <div className="flex flex-col md:flex-row justify-between items-center ">
          <div className="text-xl font-semibold align-center text-center md:text-left">
            {selectedPaper ? <div>{selectedPaper}</div> : "Please select a paper"}
          </div>
          <div className="mt-2 md:mt-0">
            <Select onValueChange={setSelectedPaper}>
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Change paper..." />
              </SelectTrigger>
              <SelectContent>
                {papers.map((paper, index) => (
                  <SelectItem key={index} value={paper.paper_name}>
                    {paper.paper_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <Separator className='my-4' orientation='horizontal' />
        {selectedPaper && selectedPaperData.coverage && selectedPaperData.coverage.total_questions_answered > 0 ? (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
              <div className="flex justify-center align-center text-center">
              <div className="mt-4 hidden lg:inline" >
                {/* desktop */}
                <PieChart
                  title="Coverage"
                  dashboard={true}
                  data={[
                    { color: 3, label: 'Not viewed', value: selectedPaperData.coverage.total_questions - selectedPaperData.coverage.total_questions_answered },
                    { color: 2, label: 'Skipped', value: 0 },
                    { color: 1, label: 'Attempted', value: selectedPaperData.coverage.total_questions_answered },
                  ]}
                  scoreChart={false}
                  mobile={false}
                />
              </div>
              {/* Tablet */}
              <div className="mt-4 hidden md:inline lg:hidden" >
                <PieChart
                  title="Coverage"
                  dashboard={true}
                  data={[
                    { color: 3, label: 'Not viewed', value: selectedPaperData.coverage.total_questions - selectedPaperData.coverage.total_questions_answered },
                    { color: 2, label: 'Skipped', value: 0 },
                    { color: 1, label: 'Attempted', value: selectedPaperData.coverage.total_questions_answered },
                  ]}
                  scoreChart={false}
                  mobile={false}
                />
              </div>
              {/* Mobile */}
              <div className="p-0 sm:hidden inline" >
                <PieChart
                  title="Coverage"
                  dashboard={true}
                  data={[
                    { color: 3, label: 'Not viewed', value: selectedPaperData.coverage.total_questions - selectedPaperData.coverage.total_questions_answered },
                    { color: 2, label: 'Skipped', value: 0 },
                    { color: 1, label: 'Attempted', value: selectedPaperData.coverage.total_questions_answered },
                  ]}
                  scoreChart={false}
                  mobile={true}
                />
              </div>
              </div>
              <div className="inline w-full lg:w-2/5 md:w-4/5 md:mx-auto lg:hidden">
                <CoverageCard
                  CPSdata={CPSdata}
                  PDdata={PDdata}
                  selected={selectedPaper}
                />
              </div>
              <div className="flex justify-center align-center text-center">
                <div className="mt-4 hidden lg:inline" >
                {/* Desktop */}
                  <PieChart
                    title="Score"
                    dashboard={true}
                    data={[
                      { color: 2, label: 'Incorrect', value: selectedPaperData.performance.total_possible_points - selectedPaperData.performance.total_points_scored },
                      { color: 1, label: 'Correct', value: selectedPaperData.performance.total_points_scored },
                    ]}
                    scoreChart={true}
                    mobile={false}
                  />
                </div>
                <div className="mt-4 hidden md:inline lg:hidden">
                {/* Tablet */}
                  <PieChart
                    title="Score"
                    dashboard={true}
                    data={[
                      { color: 2, label: 'Incorrect', value: selectedPaperData.performance.total_possible_points - selectedPaperData.performance.total_points_scored },
                      { color: 1, label: 'Correct', value: selectedPaperData.performance.total_points_scored },
                    ]}
                    scoreChart={true}
                    mobile={false}
                  />
                </div>
                <div className="p-0 sm:hidden inline">
                  {/* Mobile */}
                  <PieChart
                    title="Score"
                    dashboard={true}
                    data={[
                      { color: 2, label: 'Incorrect', value: selectedPaperData.performance.total_possible_points - selectedPaperData.performance.total_points_scored },
                      { color: 1, label: 'Correct', value: selectedPaperData.performance.total_points_scored },
                    ]}
                    scoreChart={true}
                    mobile={true}
                  />  
                </div>
                
              </div>
              <div className="inline w-full lg:w-2/5 md:w-4/5 md:mx-auto lg:hidden">
                <PerformanceCard
                  CPSdata={CPSdata}
                  PDdata={PDdata}
                  selected={selectedPaper}
                />
              </div>
            </div>
            {/* performance and coverage cards in non-mobile view */}
            <div className="hidden lg:inline">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="w-3/4 mx-auto">
                  <CoverageCard
                    CPSdata={CPSdata}
                    PDdata={PDdata}
                    selected={selectedPaper}
                  />
                </div>
                <div className="w-3/4 mx-auto">
                  <PerformanceCard
                    CPSdata={CPSdata}
                    PDdata={PDdata}
                    selected={selectedPaper}
                  />
                </div>
              </div>
            </div>
            {/* Histogram */}
            <div className="my-4">
              <div className="lg:w-full md:w-2/3 w-full mx-auto mb-4">
                <div className="text-center py-4">
                  <div className='font-semibold text-2xl sm:text-3xl'>
                    Percentile by Score
                  </div>
                </div>
                <div className="mt-4 hidden lg:inline" >
                  <BarChart data={histogramData[selectedPaper]} performancePercentage={performancePercentage} viewPort='large' />
                </div>
                <div className="mt-4 hidden md:inline lg:hidden">
                  <BarChart data={histogramData[selectedPaper]} performancePercentage={performancePercentage} viewPort='medium' />
                </div>
                <div className="p-0 sm:hidden inline">
                  <BarChart data={histogramData[selectedPaper]} performancePercentage={performancePercentage} viewPort='small' />
                </div>

              </div>
              <div className="w-full lg:w-2/5 md:w-4/5 md:mx-auto">
                <PercentileCard
                  percentileData={percentileData}
                  selected={selectedPaper}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-center text-black dark:text-white text-2xl font-bold">
              No data to analyze yet.
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PaperPerformance;
