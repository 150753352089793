import React from 'react';
import { NavLink } from 'react-router-dom';
import { Input } from '../shadcn/ui/input';
import { Separator } from '../shadcn/ui/separator';
import { 
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '../shadcn/ui/sheet'
import { ChevronDown, ChevronUp } from 'lucide-react'; 

const TextbookMobileSearch = ({ textbookItems, navigate, setSearchTerm, searchTerm }) => {

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    textbookItems.sort((a, b) => a.title.localeCompare(b.title));

    const filteredItems = textbookItems.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const categoryMap = textbookItems.reduce((acc, item) => {
        (Array.isArray(item.categories) ? item.categories : []).forEach(cat => {
          if (!acc[cat]) acc[cat] = [];
          acc[cat].push(item);
        });
        (Array.isArray(item.subcategories) ? item.subcategories : []).forEach(subcat => {
          if (!acc[subcat]) acc[subcat] = [];
          acc[subcat].push(item);
        });
        return acc;
      }, {});
      

    function Collapsible({ label, children }) {
        const [isOpen, setIsOpen] = React.useState(false);
    
        // Inline styles for animation
        const iconStyle = {
            transition: 'transform 0.3s ease',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
        };
    
        return (
            <div>
                <div 
                    onClick={() => setIsOpen(!isOpen)} 
                    className="flex items-center justify-between cursor-pointer underline p-2 my-1 text-sm text-black dark:text-white hover:bg-gray-200 dark:hover:bg-black/20 rounded"
                >
                    {label}
                    <div className="flex-shrink-0 ml-2" style={iconStyle}>
                        <ChevronDown size={16} />
                    </div>
                </div>
                {isOpen && <div>{children}</div>}
                <Separator orientation='horizontal' />
            </div>
        );
    }

    function SearchIcon(props) {
        return (
          <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
          </svg>
        )
      }

    return (
        <>
        <nav className="md:hidden inline">
            <Sheet>
            <div className="text-center">
                <SheetTrigger>
                    <div className="text-xs py-2 pl-4 italic underline">
                        Search textbook...
                    </div>
                </SheetTrigger>
            </div>
            <Separator orientation='horizontal' />
            <SheetContent side='left' className='max-h-screen overflow-y-auto'>
                <div className="pt-4">
                    <div className="relative flex-1">
                        <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500 dark:text-gray-400" />
                        <Input
                            className="w-full rounded-md bg-gray-100 px-8 py-2 text-sm shadow-none focus:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-700"
                            placeholder="Search..."
                            type="search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div className="py-2">
                    <Separator orientation='horizontal' />            
                </div>
                {searchTerm.trim() ? (
                    filteredItems.map(item => (
                        <>
                            <NavLink 
                                key={item.id} 
                                to={`/MSRA/textbook/${item.id}`}
                                className={({ isActive }) =>
                                    `block p-2 my-1 text-sm hover:bg-gray-200 dark:hover:bg-black/20 rounded ${
                                    isActive ? "bg-gray-200 dark:bg-black/20 font-semibold" : ""
                                    }`
                                }
                            >
                                <span className="ml-2">{item.title}</span>
                            </NavLink>
                            <Separator orientation="horizontal"/>
                        </>
                    ))
                ) : (
                    <>
                    <div className="text-center font-bold mt-1 mb-2">Topics</div>
                    <Separator orientation="horizontal"/>
                    {Object.keys(categoryMap).sort().map(category => (
                        <Collapsible label={category} key={category}>
                            {categoryMap[category].map(item => (
                                <NavLink key={item.id} to={`/MSRA/textbook/${item.id}`}
                                className={({ isActive }) =>
                                    `block p-2 my-1 text-sm hover:bg-gray-200 dark:hover:bg-black/20 rounded ${
                                    isActive ? "bg-gray-200 dark:bg-black/20 font-semibold" : ""
                                    }`
                                }>
                                <span className="ml-2">{item.title}</span>
                            </NavLink>
                            ))}
                        </Collapsible>
                    ))}
                    </>
                )}
            </SheetContent>
            </Sheet>
        </nav>
        </>
    );
};

export default TextbookMobileSearch;
